<template>
  <div id="body" class="py-5 md:py-0">
    <div class="flex overflow-hidden">
      <!-- <sidebar-com></sidebar-com> -->
      <div class="content" style="min-height: 97vh">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BodyCom",
  components: {
  },
};
</script>