<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "app",
  beforeMount() {
    this.$store.dispatch("getUser");
  },
};
</script>
<style></style>