<template>
  <p :style="{
    color: state.style.color,
    'font-family': state.style.font,
    'font-size': state.style.size + 'px',
    'text-align': state.style.align,
    'line-height': state.h +'px'
  }">
    {{ show }}
  </p>
</template>
<script>
export default {
  name: "ClockCom",
  props: {
    second: Number,
    text: String,
    state: Object,
    btn: String,
  },
  data() {
    return {
      count: this.second,
      message: this.text,
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
      time: '00:00:00',
      show: "",

    };
  },
  computed: {

  },
  watch: {
    $props: {
      deep: true,
      immediate: true,
      handler() {
        this.count = this.second;
        this.message = this.text;
      },
    },
    btn: function (newVal, oldVal) {
      switch (newVal) {
        case "stop":
          this.stop();
          break;
        case "reset":
          this.reset();
          break;
        case "start":
          this.start();
          break;
      }
      // if (newVal == 'stop') {
      //   this.stop();
      // }
      // else if (newVal == 'reset') {
      //   this.reset();
      // }
      // else if (newVal == 'start') {
      //   this.start();
      // }
    }
  },
  methods: {
    
    ClockRunning() {
      var self = this;

      setInterval(() => {
        var currentTime = new Date(),
        timeElapsed = new Date(currentTime - self.timeBegan - self.stoppedDuration),
        hour = timeElapsed.getUTCHours(),
        min = timeElapsed.getUTCMinutes(),
        sec = timeElapsed.getUTCSeconds();

      self.time =
      self.zeroPrefix(hour, 2) + ":" +
      self.zeroPrefix(min, 2) + ":" +
      self.zeroPrefix(sec, 2);
      }, 1000);
    },
    zeroPrefix(num, digit) {
      var zero = '';
      for (var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    reset() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = "00:00:00";
      this.ChangeMessage();
    },
    stop() {
      this.running = false;
      this.timeStopped = new Date();

      clearInterval(this.started);
    },
    start() {
      if (this.running) return;

      if (this.timeBegan === null) {
        this.reset();
        this.timeBegan = new Date();
      }

      if (this.timeStopped !== null) {
        this.stoppedDuration += (new Date() - this.timeStopped);
      }
      var self = this;
      this.started = setInterval(() => {
        if (this.count == 0) {
          var currentTime = new Date();
        } else {
          var d = new Date();
          d.setSeconds(d.getSeconds() + this.count);
          var currentTime = d;
          
        }
        var timeElapsed = new Date(currentTime - self.timeBegan - self.stoppedDuration),
        hour = timeElapsed.getUTCHours(),
        min = timeElapsed.getUTCMinutes(),
        sec = timeElapsed.getUTCSeconds();

      self.time =
      self.zeroPrefix(hour, 2) + ":" +
      self.zeroPrefix(min, 2) + ":" +
      self.zeroPrefix(sec, 2);
        self.ChangeMessage();
      }, 1000);
      this.running = true;
    },

    ChangeMessage() {
      var time = this.time;
      var letter = ["{{hours}}", "{{minutes}}", "{{seconds}}"];
      var text = this.message;
      var string = "";
      for (var j = 0; j < letter.length; j++) {
        switch (text.includes(letter[j])) {
          case true:
            string = text.replace(letter[j], time.split(":")[j]);
            text = string;
            break;
        }
      }
      this.show = string;
    },
  },
  mounted() {
    this.start();
  },
};
</script>