<template>
  <!-- BEGIN: Top Bar -->
  <div class="
      md:h-[65px]
      border-b border-white/[0.08]
      h-[70px]
      z-[51]
      top-bar-boxed
      mt-12
      md:mt-0
      -mx-3
      sm:-mx-8
      md:-mx-0
      px-3
      md:border-b-0
      sm:px-8
      md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent
      dark:md:from-darkmode-700
      md:fixed md:inset-x-0 md:top-0
      relative
    " v-if="showTemplate">
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <a href="" class="logo -intro-x hidden md:flex xl:w-[180px] block">
        <img alt="Midone - HTML Admin Template" class="logo__image w-6" src="../assets/dist/images/ty.png" />
        <span class="logo__text text-white text-lg ml-3">TY Media</span>
      </a>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <nav aria-label="breadcrumb" class="-intro-x h-[45px] mr-auto">
        <ol class="breadcrumb breadcrumb-light">
          <li class="breadcrumb-item">
            <a href="#">{{ titleA }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ titleB }}
          </li>
        </ol>
      </nav>
      <!-- END: Breadcrumb -->

      <!-- END: Search -->

      <!-- BEGIN: Account Menu -->
      <div class="intro-x dropdown w-8 h-8">
        <div class="
            dropdown-toggle
            w-8
            h-8
            rounded-full
            overflow-hidden
            shadow-lg
            image-fit
            zoom-in
            scale-110
          " id="defaultDropdown" role="button" aria-expanded="false" data-tw-toggle="dropdown">
          <img alt="Midone - HTML Admin Template" src="/profile.png" />
        </div>
        <div class="dropdown-menu w-56" aria-labelledby="defaultDropdown">
          <ul class="
              dropdown-content
              bg-primary/80
              before:block
              before:absolute
              before:bg-black
              before:inset-0
              before:rounded-md
              before:z-[-1]
              text-white
            ">
            <li class="p-2">
              <div class="font-medium">{{ username }}</div>
              <div class="text-xs text-white/60 mt-0.5 dark:text-slate-500"></div>
            </li>
            <!-- <li>
              <hr class="dropdown-divider border-white/[0.08]" />
            </li> -->
            <!-- <li>
              <a href="" class="dropdown-item hover:bg-white/5">
                <i data-lucide="user" class="w-4 h-4 mr-2 pi pi-user"></i>
                Profile
              </a>
            </li>
            <li>
              <a href="" class="dropdown-item hover:bg-white/5">
                <i data-lucide="edit" class="w-4 h-4 mr-2 pi pi-user-plus"></i>
                Add Account
              </a>
            </li> -->
            <li>
              <router-link to="/change-password" class="dropdown-item hover:bg-white/5" @click="HideDropdown">
                <i data-lucide="lock" class="w-4 h-4 mr-2 pi pi-unlock"></i>
                Reset Password
              </router-link>
            </li>
            <!-- <li>
              <a href="" class="dropdown-item hover:bg-white/5">
                <i
                  data-lucide="help-circle"
                  class="w-4 h-4 mr-2 bi bi-question-circle"
                ></i>
                Help
              </a>
            </li> -->
            <li>
              <hr class="dropdown-divider border-white/[0.08]" />
            </li>
            <li>
              <router-link to="/login" @click="logout"><i data-lucide="toggle-right"
                  class="w-4 h-4 mr-2 bi bi-box-arrow-left"></i>
                Logout</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>
<script>
import axios from "axios";
export default {
  name: "HeaderCom",
  data() {
    return {
      showTemplate: false,
      username: "",
    };
  },
  props: {
    titleA: String,
    titleB: String,
  },
  mounted() {
    this.username = this.$store.state.username;
    this.showTemplate = true;
  },
  methods: {

    HideDropdown() {
      const el = document.querySelector("#defaultDropdown");
      const dropdown = tailwind.Dropdown.getOrCreateInstance(el);
      dropdown.hide();

    },
    logout() {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios.get("logout", config).then((resp) => {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("id");
        this.$router.push("login");
        this.HideDropdown();
      });
    },
  },
};
</script>