<template>
    <div v-if="!state.style.animation" :style="{ 'z-index': state.z }">
        <div v-if="state.source != ''">
            <video :autoplay="state.autoplay" :muted="state.muted" :loop="state.loop"
                :style="{ '--delay': state.style.delay + 's', '--width': state.w + 'px', '--height': state.h + 'px' }"
                id="video" :transition-style="state.style.transition" class="inner">
                <source :src="source + '?' + state.style.time" type="video/mp4" v-if="source != ''" />
            </video>
            <back-state-com :state="state" :source="source"
                v-if="state.style.state && state.style.swapState == false" />
        </div>

        <video v-else :autoplay="state.autoplay" :muted="state.muted" :loop="state.loop"
            :style="{ '--delay': state.style.delay + 's', '--width': state.w + 'px', '--height': state.h + 'px' }"
            id="video" :transition-style="state.style.transition" class="">
            <source :src="''" type="video/mp4" />
        </video>
    </div>

    <div v-else class="relative overflow-hidden"
        :style="{ 'width': state.w + 'px', 'height': state.h + 'px', 'z-index': state.z }">
        <div v-if="state.source != ''">
            <transition name="nested" :duration="{ enter: 5000, leave: 500 }">
                <div v-if="show" class="absolute inset-0 flex items-center justify-center backImage"
                    :style="{ '--intro': 'url(' + link + state.style.introSource + ')' }">
                    <video v-if="state.source != ''" :autoplay="state.autoplay" :muted="state.muted" :loop="state.loop"
                        :style="{ '--width': state.w + 'px', '--height': state.h + 'px' }" id="video"
                        class="inner w-full h-full object-cover">
                        <source :src="source + '?' + state.style.time" type="video/mp4" v-if="source != ''" />
                    </video>
                </div>
            </transition>
            <back-state-com :state="state" :source="source"
                v-if="state.style.state && state.style.swapState == false" />
        </div>

        <div v-else :style="{ 'z-index': state.z }">
            <video :autoplay="state.autoplay" :muted="state.muted" :loop="state.loop"
                :style="{ '--delay': state.style.delay + 's', '--width': state.w + 'px', '--height': state.h + 'px' }"
                id="video" :transition-style="state.style.transition">
                <source :src="''" type="video/mp4" />
            </video>
        </div>
    </div>
</template>

<script>
import BackStateCom from "../components/BackState.vue";

export default {
    name: "VideoCom",
    props: {
        texts: Number,
        state: Object,
        source: String,
    },
    components: {
        BackStateCom,
    },
    data() {
        return {
            showTemplate: true,
            text: this.texts,
            show: false,
            link: process.env.VUE_APP_Link,
            test: false,
        };
    },

    mounted() {
        this.show = true;
    },
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
    animation: slide-down 0.5s;
}

.fade-leave-active {
    animation: slide-up 1s;
}

.fade-enter-from {
    transform: translateY(-300px);
}

.fade-leave-to {
    transform: translateY(0);
}

video[src=""] {
    display: none;
}

.nested-enter-active {
    animation: slide-down 0.5s;
}

.nested-leave-active {
    animation: slide-up 1s;
}

.nested-enter-active .inner {
    transition: all 1.5s ease-in-out;
}

.nested-enter-active .inner {
    transition: 0.5s all 1.5s ease-in-out;
}

.nested-enter-from .inner {
    height: 0px;
}

.nested-leave-to .inner {
    height: 300px;
}

@keyframes slide-down {
    0% {
        transform: translateY(-300px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-300px);
    }
}

.backImage {
    background-image: var(--intro);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.backStateDown {
    animation-delay: 10s;
    animation: slide-down 1.5s;
    // animation: slide-up 1.5s;

}

.backStateUp {
    animation: slide-up 1.5s;
}
</style>