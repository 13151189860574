
<template>
  <div class="login">
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <!-- <a href="" class="-intro-x flex items-center pt-5">
            <img alt="Midone - HTML Admin Template" class="w-6" src="../assets/dist/images/ty.png" />
            <span class="text-white text-xl ml-3"> TY Media </span>
          </a> -->
          <div class="my-auto">
            <img alt="Midone - HTML Admin Template" class="-intro-x w-1/2 -mt-16"
              src="../assets/dist/images/ty-logo.png" />
            <!-- <div class="
                -intro-x
                text-white
                font-medium
                text-4xl
                leading-tight
                mt-10
              ">
              A few more clicks to
              <br />
              sign in to your account.
            </div>
            <div class="
                -intro-x
                mt-5
                text-lg text-white text-opacity-70
                dark:text-slate-400
              ">
              Manage all your e-commerce accounts in one place
            </div> -->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-darkmode-600
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            ">
            <h2 class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              ">
              Sign In
            </h2>
            <div class="intro-x mt-2 text-slate-400 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <div class="intro-x mt-8">
              <input type="email" v-model="email" class="intro-x login__input form-control py-3 px-4 block"
                placeholder="example@email.com" id="inputUser" @keyup.enter.prevent="login" autofocus />
              <small v-show="userError != ''" class="error-msg float-left m-2" style="color: red; margin: 2px">
                {{ userError }}
              </small>
              <input type="password" v-model="password" class="intro-x login__input form-control py-3 px-4 block mt-4"
                placeholder="password" id="inputPass" @keyup.enter.prevent="login" />
              <small v-show="passError != ''" class="error-msg my-2"
                style="color: red; text-align: left; margin-top: 2px">
                {{ passError }}
              </small>
            </div>
            <div class="
                intro-x
                flex
                text-slate-600
                dark:text-slate-500
                text-xs
                sm:text-sm
                mt-4
              ">
              <div class="flex items-center mr-auto">
                <input id="remember-me" type="checkbox" class="form-check-input border mr-2" v-model="rememberMe"
                  @keyup.enter.prevent="login" />
                <label class="cursor-pointer select-none" for="remember-me">Remember me</label>
              </div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">

              <button class="
                    btn btn-primary
                    py-3
                    px-4
                    w-full
                    xl:w-32 xl:mr-3
                    align-top
                  " @click="login">
                Login
              </button>
              <!-- :disabled="v$.username.$error || v$.password.$error" -->
              <!-- <router-link to="/register"><button class="
                    btn btn-outline-secondary
                    py-3
                    px-4
                    w-full
                    xl:w-32
                    mt-3
                    xl:mt-0
                    align-top
                  ">
                  Register
                </button></router-link> -->
            </div>

          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginView",
  components: {},
  data() {
    return {
      email: localStorage.getItem("email") || null,
      password: "",
      token: localStorage.getItem("token") || null,
      rememberMe: localStorage.getItem("remember_me") || false,
      passError: "",
      userError: "",
    };
  },
  watch: {
    email() {
      this.userError = "";
      document.getElementById("inputUser").classList.remove("border-danger");
    },
    password() {
      this.passError = "";
      document
        .getElementById("inputPass")
        .classList.remove("border-danger");
    },
  },
  methods: {
    login() {
      axios
        .post("login", {
          email: this.email,
          password: this.password,
        })
        .then((resp) => {
          if (resp.data.status == "success") {
            if (this.rememberMe == true || this.rememberMe == "true") {
              if (localStorage.getItem("email") !== this.email) {
                localStorage.setItem("email", this.email);
              }
              if (localStorage.getItem("remember_me") !== true) {
                localStorage.setItem("remember_me", true);
              }
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("remember_me");
            }

            this.token = resp.data.data;
            localStorage.setItem("token", this.token);
            const config = {
              headers: { Authorization: `Bearer ${resp.data.data}` },
            };

            axios
              .get("get_a_user", config)
              .then((resp) => {
                if (resp.status == 200) {
                  localStorage.setItem(
                    "username",
                    resp.data.data.fname + " " + resp.data.data.lname
                  );

                  var id = this.encrypt(resp.data.data.id.toString());
                  localStorage.setItem('id', id);
                  this.$store.dispatch("getUser");
                  this.$router.go("/dashboard");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            if (resp.data.code == 400) {
              this.passError = resp.data.message;
              this.userError = "";
              document
                .getElementById("inputUser")
                .classList.remove("border-danger");
              document
                .getElementById("inputPass")
                .classList.add("border-danger");
              document.getElementById("inputPass").focus();
            } else if (resp.data.code == 404) {
              this.passError = "";
              this.userError = resp.data.message;
              document
                .getElementById("inputPass")
                .classList.remove("border-danger");
              document.getElementById("inputUser").focus();
              document
                .getElementById("inputUser")
                .classList.add("border-danger");
            }

          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          console.log("Credenciales Incorrectas", "", "error");
        });
    },
    encrypt(src) {
      const passphrase = "Overlay";
      return this.$CryptoJS.AES.encrypt(src, passphrase).toString();
    },
    // decrypt(src) {
    //   if (src != null) {
    //     const passphrase = "Overlay";
    //     const bytes = this.$CryptoJS.AES.decrypt(src, passphrase);
    //     const originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
    //     return originalText;
    //   } else {
    //     return src;
    //   }
    // },
  },
};
</script>