<template>
  <header-con :title-a="'Application'" :title-b="'Change Password'"></header-con>

  <div class="mt-5">
    <div class="intro-y flex items-center">
      <h2 class="text-lg font-medium mr-auto">
        Change Password
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6">

      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div class="p-5">
            <div>
              <label for="change-password-form-1" class="form-label">Current Password</label>
              <input id="curPassword" type="password" class="form-control" v-model="curPassword"
                placeholder="Current Password" @keyup.enter.prevent="save">
              <small v-show="curPasswordError != ''" class="error-msg " style="color: red">
                {{ curPasswordError }}
              </small>
            </div>
            <div class="mt-3">
              <label for="change-password-form-2" class="form-label">New Password</label>
              <input id="password" type="password" class="form-control" v-model="newPassword" placeholder="New Password"
                @keyup.enter.prevent="save">
              <small v-show="passwordError != ''" class="error-msg " style="color: red">
                {{ passwordError }}
              </small>
            </div>
            <div class="mt-3">
              <label for="change-password-form-3" class="form-label">Confirm New Password</label>
              <input id="conPassword" type="password" class="form-control" v-model="conPassword"
                placeholder="Confirm new password" @keyup.enter.prevent="save">
              <small v-show="conPasswordError != ''" class="error-msg " style="color: red">
                {{ conPasswordError }}
              </small>
            </div>
            <button type="button" class="btn btn-primary mt-4" @click="save">Update</button>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
  </div>
  <Toast position="bottom-right" group="br" />
</template>

<script>
import "primeflex/primeflex.css";
import HeaderCon from "../components/header.vue";
import Toast from "primevue/toast";
import axios from "axios";

export default {
  name: "ChangePasswordView",
  components: {
    HeaderCon,
    Toast,

  },
  data() {
    return {
      curPassword: "",
      newPassword: "",
      conPassword: "",
      curPasswordError: "",
      passwordError: "",
      conPasswordError: "",
    }
  },
  methods: {
    save() {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        curPassword: this.curPassword,
        password: this.newPassword,
        conPassword: this.conPassword
      };

      axios.post("change_password", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.curPassword = "";
          this.newPassword = "";
          this.conPassword = "";
          this.Toast("success", "Success", "");

        }
        else {
          Object.entries(resp.data.message).forEach((entry) => {
            const [key, value] = entry;
            if (key == "curPassword") {
              this.curPasswordError = value[0];
              document
                .getElementById("curPassword")
                .classList.add("border-danger");
            } else if (key == "password") {
              this.passwordError = value[0];
              document.getElementById("password").classList.add("border-danger");
            } else if (key == "conPassword") {
              this.conPasswordError = value[0];
              document
                .getElementById("conPassword")
                .classList.add("border-danger");
            }
          });
        }
      }).catch((err) => {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem("id");
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      });
    },

    Toast(state, sum, det) {
      this.$toast.add({
        severity: state,
        summary: sum,
        detail: det,
        group: "tr",
        life: 1000,
      });
    },
  },
  watch: {
    password() {
      this.passwordError = "";
      try {
        document.getElementById("password").classList.remove("border-danger");
      } catch (e) { }
    },
    conPassword() {
      this.conPasswordError = "";
      try {
        document
          .getElementById("conPassword")
          .classList.remove("border-danger");
      } catch (e) { }
    },
    curPassword() {
      this.curPasswordError = "";
      try {
        document
          .getElementById("curPassword")
          .classList.remove("border-danger");
      } catch (e) { }
    },
  }
}

</script>