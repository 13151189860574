<template>
  <div v-if="!state.style.animation" :style="{ 'z-index': state.z }">
    <div v-if="state.source != ''">
      <img class="logo overrides" :src="source" alt=""
        :style="{ '--delay': state.style.delay + 's', 'border-radius': state.style.radius + '%', '--width': state.w + 'px', '--height': state.h + 'px' }"
        :transition-style="state.style.transition" :class="{ grayClass: state.style.gray }" />
      <back-state-com  :state="state" :source="source"
        v-if="state.style.state && state.style.swapState == false" />

    </div>

    <img class="logo overrides" :src="''" alt="" :style="{ '--delay': state.style.delay + 's' }" v-else
      :transition-style="state.style.transition" :class="{ grayClass: state.style.gray }" />
  </div>

  <div v-else class="relative overflow-hidden"
    :style="{ 'width': state.w + 'px', 'height': state.h + 'px', 'z-index': state.z }">
    <transition name="nested" :duration="{ enter: 5000, leave: 500 }">
      <div v-if="show" class="absolute inset-0 flex items-center justify-center backImage"
        :style="{ '--intro': 'url(' + link + state.style.introSource + ')' }">
        <img class="inner w-full h-full object-cover" :src="source">
      </div>
    </transition>
    <back-state-com  :state="state" :source="source"
      v-if="state.style.state && state.style.swapState == false" />

  </div>
</template>

<script>
import BackStateCom from "../components/BackState.vue";

export default {
  name: "ImageCom",
  props: {
    text: String,
    state: Object,
    source: String,
  },
  components: {
    BackStateCom,
  },
  data() {
    return {
      show: false,
      link: process.env.VUE_APP_Link,

    }
  },

  mounted() {
    this.show = true;
  }
}
</script>

<style lang="scss" scoped>
img[src=""] {
  display: none;
}

.nested-enter-active {
  animation: slide-down 0.5s;
}

.nested-leave-active {
  animation: slide-up 0.5s;
}

.nested-enter-active .inner {
  transition: all 1s ease-in-out;
}

.nested-enter-active .inner {
  transition: 1s all 1s ease-in-out;
}

.nested-enter-from .inner {
  height: 0px;
}

.nested-leave-to .inner {
  height: 300px;
}

@keyframes slide-down {
  0% {
    transform: translateY(-300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-300px);
  }
}

.backImage {
  background-image: var(--intro);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.overrides {
  --transition__duration: 1s;
  /* default: 2.5s */
  --transition__easing: linear;
  /* default: cubic-bezier(.25, 1, .30, 1) */
  --transition__delay: var(--delay);
  /* default: 0 */
}

.grayClass {
  filter: grayscale(100%);
}

@keyframes blinkingBackground {
  0% {
    opacity: 100%;
  }

  25% {
    opacity: 10%;
  }

  50% {
    opacity: 100%;
  }

  75% {
    opacity: 10%;
  }

  100% {
    opacity: 100%;
  }
}

.blinking {
  animation: blinkingBackground 2s infinite;
}
</style>