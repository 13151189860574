import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
const config = {

//server

  // apiKey: 'AIzaSyA2koKd2gGBQ23nW1Hnbvt9YseLkuC_Fd8',
  // authDomain: 'overlay-a6f91.firebaseapp.com',
  // databaseURL:
  //   'https://overlay-a6f91-default-rtdb.asia-southeast1.firebasedatabase.app',
  // projectId: 'overlay-a6f91',
  // storageBucket: 'overlay-a6f91.appspot.com',
  // messagingSenderId: '830131882341',
  // appId: '1:830131882341:web:b6d963b2d97ceacfa4f98c',

//local

  apiKey: "AIzaSyDyFrODgB8CviaBUouCBVoR8IDSDy8UaGM",
  authDomain: "test-overlay.firebaseapp.com",
  databaseURL: "https://test-overlay-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "test-overlay",
  storageBucket: "test-overlay.appspot.com",
  messagingSenderId: "811475740194",
  appId: "1:811475740194:web:f1ff3dc4812a1b06c28c7b",
  measurementId: "G-QBLBHM2Z1V"
};
const app = initializeApp(config);
const db = getDatabase(app);

// const app = firebase.initializeApp(config);
// const db = firebase.database(app);



export default db ;
