import { createStore } from 'vuex';
import axios from 'axios';
import router from '@/router';
export default createStore({
  state: {
    token: localStorage.getItem('token'),
    username: localStorage.getItem('username'),
    email: localStorage.getItem('email'),
  },
  getters: {},
  mutations: {
    getUser(state, data) {
      state.username = data.data.fname + ' ' + data.data.lname;
      localStorage.setItem('username', data.data.fname + ' ' + data.data.lname);
      localStorage.setItem('email', data.data.email);
    },
  },
  actions: {
    getUser({ commit }) {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var url = window.location.pathname;

      if (
        url !== '/login' &&
        url != '/register' &&
        url != '/live' &&
        url != '/graph'
      ) {
        axios
          .get('get_a_user', config)
          .then((resp) => {
            commit('getUser', resp.data);
          })
          .catch((err) => {
            if (err.request) {
              localStorage.removeItem('token');
              localStorage.removeItem('username');
              localStorage.removeItem('id');
              router.push('login');
            } else if (err.response) {
              if (err.response.statusText == 'Unauthorized') {
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                localStorage.removeItem('id');
                router.push('login');
              } else {
                console.log(err.response.statusText);
              }
            }
          });
      }
    },
  },

  modules: {},
});
