<template>
  <div v-if="showTempalte">
    <header-con v-if="event" :title-a="'Application'" :title-b="'Dashboard'"></header-con>
    <header-con v-else :title-a="'Event'" :title-b="this.eventTitle"></header-con>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 lg:col-span-12 2xl:col-span-12">
        <!-- BEGIN: File Manager Filter -->
        <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
          <div class="w-full sm:w-auto relative mr-auto mt-3 sm:mt-0">
            <i v-if="!event" class="
                w-5
                h-5
                my-auto
                inset-y-0
                ml-5
                left-0
                z-10
                text-slate-500
                pi pi-arrow-circle-left
              " style="font-size: 1.75rem;cursor: pointer;" @click="this.$router.back(); this.eventTitle = ''"></i>
            <i class="
                w-5
                h-5
                my-auto
                inset-y-0
                ml-5
                left-0
                z-10
                text-slate-500
                pi pi-undo
              " style="font-size: 1.75rem;cursor: pointer;" @click="this.$router.go();"></i>


          </div>
          <div class="w-full sm:w-auto flex">
            <button v-if="event" class="btn btn-primary shadow-md mr-2" @click="NewEvent">
              Add New Event
            </button>
            <button v-else class="btn btn-primary shadow-md mr-2" @click="NewOverlay">
              Add New Overlay
            </button>

          </div>
        </div>
        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
          <div v-for="overlay in overlays" :key="overlay.id" class="
              intro-y
              col-span-6
              sm:col-span-4
              md:col-span-3
              2xl:col-span-2
            ">
            <div class="
                file
                box
                rounded-md
                px-5
                pt-8
                pb-5
                px-3
                sm:px-5
                relative
                zoom-in
                image
              ">
              <div class="absolute left-0 top-0 mt-3 ml-3">
                <input class="form-check-input border border-slate-500" type="checkbox" :checked="overlay.status"
                  disabled />
                <!-- @click="ShowOverlay(overlay.id, overlay.status)"  -->
              </div>

              <a v-if="event" href="javascript:;" class="w-3/5 file__icon file__icon--image mx-auto"
                @click="ShowChildOverlay(overlay.id, overlay.title)">
                <div class="file__icon--image__preview image-fit">
                  <img alt="Midone - HTML Admin Template" src="../assets/dist/images/group.png" />
                </div>
              </a>
              <a v-else-if="event == false && overlay.graph == 0" href="javascript:;"
                class="w-3/5 file__icon file__icon--image mx-auto" @click="EditOverlay(overlay.id)">
                <div class="file__icon--image__preview image-fit">
                  <img alt="Midone - HTML Admin Template" src="../assets/dist/images/layer.png" />
                </div>
              </a>
              <a v-else href="javascript:;" class="w-3/5 file__icon file__icon--image mx-auto"
                @click="ShowGraphOverlay(overlay.id)">
                <div class="file__icon--image__preview image-fit">
                  <img alt="Midone - HTML Admin Template" src="../assets/dist/images/layer.png" />
                </div>
              </a>
              <a href="javascript:;" class="block font-medium mt-4 text-center truncate name">{{ overlay.id }} {{
    overlay.title }}</a>
              <div class="text-slate-500 text-xs text-center mt-0.5">
                {{ overlay.date }}
              </div>
              <div class="absolute top-0 right-0 mr-2 mt-3 dropdown ml-auto">
                <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"
                  data-tw-toggle="dropdown" id="defaultDropdown">
                  <i data-lucide="more-vertical" class="w-5 h-5 text-slate-500 bi bi-three-dots"></i>
                </a>
                <div class="dropdown-menu w-40">
                  <ul class="dropdown-content">
                    <!-- <li>
                      <a v-if="!event" href="" class="dropdown-item" @click.prevent="LinkLive(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-link-45deg"></i>
                        Copy Link Live
                      </a>
                    </li> -->
                    <!-- <li>
                      <a v-if="!event" href="" class="dropdown-item" @click.prevent="ShowLive(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-link-45deg"></i>
                        Copy ID
                      </a>
                    </li> -->
                    <li>
                      <a v-if="event" href="" class="dropdown-item" @click.prevent="SetActive(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-star-half"></i>
                        Set Active
                      </a>
                      <!-- <a v-else href="" class="dropdown-item" @click.prevent="ShowDialog(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-pencil-square"></i>
                        Properties
                      </a> -->
                    </li>
                    <li>
                      <a v-if="event" href="" class="dropdown-item" @click.prevent="ShowNameDialog(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-pencil-square"></i>
                        Properties
                      </a>
                      <a v-else href="" class="dropdown-item" @click.prevent="ShowDialog(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-pencil-square"></i>
                        Properties
                      </a>
                    </li>

                    <li>
                      <a v-if="event" href="" class="dropdown-item" @click.prevent="ShowCopyEventDialog(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-clipboard2"></i>
                        Duplicate
                      </a>
                      <a v-else href="" class="dropdown-item" @click.prevent="ShowCopyOverlayDialog(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-clipboard2"></i>
                        Duplicate
                      </a>
                    </li>
                    <!-- <li v-if="!event">
                      <a href="" class="dropdown-item" @click.prevent="ShowNextScreen(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-arrow-up-right-square"></i>
                        Next screen
                      </a>
                    </li> -->
                    <li v-if="!event && overlay.title != 'Gold Lead' && overlay.title != 'Current Health'">
                      <a href="" class="dropdown-item" @click.prevent="ShowLink(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-link"></i>
                        Link to
                      </a>
                    </li>
                    <li>
                      <a v-if="event" href="" class="dropdown-item" @click.prevent="DeleteEvent(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-trash3-fill"></i>
                        Delete
                      </a>
                      <a v-else href="" class="dropdown-item" @click.prevent="DeleteOverlay(overlay.id)">
                        <i class="w-4 h-4 mr-2 bi bi-trash3-fill"></i>
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Directory & Files -->
        <!-- BEGIN: Pagination -->
        <!-- flex flex-wrap -->
        <div class="intro-y sm:flex-row sm:flex-nowrap items-center mt-6">
          <Paginator v-if="event" :rows="18" :totalRecords="total" :rowsPerPageOptions="[18, 36, 72]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport "
            currentPageReportTemplate="{last} of {totalRecords} events" @page="OnPage($event)">
          </Paginator>
        </div>
        <!-- END: Pagination -->
      </div>
    </div>
  </div>
  <Dialog v-model:visible="eventDialog" :style="{ width: '500px' }" :modal="true" header="Add New Event"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <!-- <div class="
              flex
              items-center
              p-5
              border-b border-slate-200/60
              dark:border-darkmode-400
            ">
            <h2 class="font-medium text-base mr-auto">Overlay Property</h2>
          </div> -->
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <label for="update-profile-form-6" class="form-label">Title</label>
                  <input id="update-profile-form-6" type="text" class="form-control" placeholder="Title"
                    v-model="eventTitle" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="HideEvent">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveEvent(eventTitle)">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="propDialog" :style="{ width: '500px' }" :modal="true" header="Overlay Property"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <!-- <div class="
              flex
              items-center
              p-5
              border-b border-slate-200/60
              dark:border-darkmode-400
            ">
            <h2 class="font-medium text-base mr-auto">Overlay Property</h2>
          </div> -->
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <label for="update-profile-form-6" class="form-label">Title</label>
                  <input id="update-profile-form-6" type="text" class="form-control" placeholder="Title"
                    v-model="component.title" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="HideDialog">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveProp">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="linkDialog" :style="{ width: '500px' }" header="Overlay Link" :modal="true"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <!-- <div class="
              flex
              items-center
              p-5
              border-b border-slate-200/60
              dark:border-darkmode-400
            ">
            <h2 class="font-medium text-base mr-auto">Overlay Link</h2>
          </div> -->
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <MultiSelect v-model="selectOverlays" :options="listOverlays" optionValue="id" optionLabel="title"
                    placeholder="Select overlay" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="linkDialog = false">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveLink()">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="screenDialog" :style="{ width: '500px' }" header="Next Screen Overlay" :modal="true"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <Dropdown v-model="selectOverlay" :options="listOverlays" optionLabel="title" optionValue="id"
                    placeholder="Select overlay" :showClear="true" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="screenDialog = false">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveNextScreen()">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="nameEventDialog" :style="{ width: '500px' }" :modal="true" header="Event Title"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <label for="update-profile-form-6" class="form-label">Title</label>
                  <input id="update-profile-form-6" type="text" class="form-control" placeholder="Title"
                    v-model="component.title" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="nameEventDialog = false">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveName">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="copyEventDialog" :style="{ width: '500px' }" :modal="true" header="Copy Event"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <label for="update-profile-form-6" class="form-label">Title</label>
                  <input id="update-profile-form-6" type="text" class="form-control" placeholder="Title"
                    v-model="this.component.title" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="copyEventDialog = false">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveCopyEvent">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="copyOverlayDialog" :style="{ width: '500px' }" :modal="true" header="Copy Overlay"
    :draggable="false" class="p-fluid" :closeOnEscape="false" :closable="false">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <label for="update-profile-form-6" class="form-label">Title</label>
                  <input id="update-profile-form-6" type="text" class="form-control" placeholder="Title"
                    v-model="this.component.title" />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" class="btn btn-secondary w-20 mr-1" @click="copyOverlayDialog = false">
                Cancel
              </button>
              <button type="button" class="btn btn-primary w-20 mr-1" @click="SaveCopyOverlay">
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Toast position="top-right" group="tr" />
</template>

<script>
import "primeflex/primeflex.css";
import axios from "axios";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import HeaderCon from "../components/header.vue";
import db from "../firebase";
import { ref, set } from "firebase/database";

export default {
  name: "OverlayView",
  components: {
    Toast,
    HeaderCon,
    Dialog,
  },
  data() {
    return {
      showTempalte: false,
      propDialog: false,
      eventDialog: false,
      linkDialog: false,
      screenDialog: false,
      nameEventDialog: false,
      copyEventDialog: false,
      copyOverlayDialog: false,
      products: null,
      layout: "grid",
      overlays: [],
      listOverlays: [],
      search: "",
      total: 0,
      lazyParams: {},
      timeout: null,
      overlay: [],
      component: [],
      selectOverlays: null,
      selectOverlay: null,
      id: "",
      overlayId: "",
      inputsAll: [],
      eventTitle: "",
      event: false,
      oldName: "",
    };
  },

  mounted() {
    this.lazyParams = {
      page: 0,
      first: 0,
      rows: 18,
      search: "",
      id: "",
    };
    if (this.$route.params.id == null) {
      this.GetEvent();
    }
    else {
      this.GetOverlayByEvent(this.$route.params.id);
    }
    this.showTempalte = true;
  },
  watch: {
    $route(to, from) {
      if (to.name == 'home') {
        this.GetEvent();
        localStorage.removeItem('event_id');
      }
    }
  },
  methods: {
    test() {
      alert('test');
    },

    GetAOverlay(id) {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
      };

      axios.post("get_overlay_by_id", data, config).then((resp) => {
        this.overlay = JSON.parse(resp.data.data.component);
        this.component = resp.data.data;
        this.selectOverlays = resp.data.link;
        this.selectOverlay = parseInt(resp.data.data.next_screen);
        this.oldName = resp.data.data.title;
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    GetAEvent(id) {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
      };

      axios.post("get_event_by_id", data, config).then((resp) => {
        this.component = resp.data.data;
        this.oldName = resp.data.data.title;
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    NewEvent() {
      this.eventTitle = '';
      this.eventDialog = true;
    },

    NewOverlay() {
      let routeData = this.$router.resolve({
        path: "/overlay-editor",
      });
      window.open(routeData.href, "_blank");
    },

    EditOverlay(id) {
      var string = id.toString();
      var key = this.encrypt(string);
      let routeData = this.$router.resolve({
        name: "overlay-editor",
        query: { data: key },
      });
      window.open(routeData.href, "_blank");
    },

    ShowGraphOverlay(id) {
      var string = id.toString();
      // var key = this.encrypt(string);
      var token = this.$store.state.token;
      var newToken = string + "mmm" + token;
      let routeData = this.$router.resolve({
        name: "graph",
        query: { data: newToken },
      });
      window.open(routeData.href, "_blank");
    },

    ShowChildOverlay(id, title) {
      this.eventTitle = title;
      this.$router.push({ path: `dashboard/${id}` })
      this.GetOverlayByEvent(id);
      localStorage.setItem('event_id', this.encrypt(id.toString()));
    },

    DeleteOverlay(id) {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
      };

      axios.post("delete_overlay", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.HideDropdown();
          this.GetOverlayByEvent(this.$route.params.id);

          this.Toast("success", "Success", "");
        } else {
          this.Toast("error", "Fail", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    DeleteEvent(id) {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
      };

      axios.post("delete_event", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.HideDropdown();
          this.GetEvent();
          this.Toast("success", "Success", "");
        } else {
          this.Toast("error", "Fail", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    TransitionOut(time) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].type == "image") {
          this.inputsAll[i].style.transition = this.inputsAll[i].style.transitionOut;
          this.inputsAll[i].style.delay = 0;
          this.inputsAll[i].style.time = time + i;
        }
        else {
          this.inputsAll[i].hide = true;
          this.inputsAll[i].style.time = time + i;
        }
      }
    },

    TransitionIn(time) {
      for (var i = 0; i < this.inputsAll.length; i++) {
        if (this.inputsAll[i].type == "image") {
          this.inputsAll[i].style.transition = this.inputsAll[i].style.transitionIn;
          this.inputsAll[i].style.delay = this.inputsAll[i].style.delayIn;
          this.inputsAll[i].style.time = time + i;
        }
        else {
          this.inputsAll[i].hide = false;
          this.inputsAll[i].style.time = time + i;
        }
      }
    },

    CopyEvent(id) {
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
        title: title,
      };

      axios.post("copy_event", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.HideDropdown();
          this.GetEvent();
          this.Toast("success", "Success", "");
        } else {
          this.Toast("error", "Fail", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    encrypt(src) {
      const passphrase = "Overlay";
      return this.$CryptoJS.AES.encrypt(src, passphrase).toString();
    },

    decrypt(src) {
      if (src != null) {
        const passphrase = "Overlay";
        const bytes = this.$CryptoJS.AES.decrypt(src, passphrase);
        const originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
        return originalText;
      } else {
        return src;
      }
    },

    HideDialog() {
      this.propDialog = false;
    },

    ShowDialog(id) {
      this.HideDropdown();
      this.GetAOverlay(id);
      this.propDialog = true;
    },

    async ShowLive(id) {
      try {
        await navigator.clipboard.writeText(id);
        this.HideDropdown();
        this.Toast("success", "Copied", "");
      }
      catch ($e) {
        this.HideDropdown();
        this.Toast("fail", "Cannot copy", "");
      }
    },

    async LinkLive(id) {
      var string = id.toString();
      var token = localStorage.getItem('token');
      var newToken = id + "mmm" + token;
      let routeData = this.$router.resolve({
        name: "live",
        query: { data: newToken },
      });
      window.open(routeData.href, "_blank");
    },

    async SetActive(id) {
      var data = {
        id: id,
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await axios.post("set_active_event", data, config).then((resp) => {
        this.HideDropdown();

        if (resp.data.status == "success") {
          this.GetEvent();
          this.Toast("success", "Saved", "");
        } else {
          this.Toast("error", "Fail!", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    ShowNameDialog(id) {
      this.HideDropdown();
      this.GetAEvent(id);
      this.nameEventDialog = true;
    },

    ShowCopyEventDialog(id) {
      this.HideDropdown();
      this.GetAEvent(id);
      this.copyEventDialog = true;
    },

    ShowCopyOverlayDialog(id) {
      this.HideDropdown();
      this.GetAOverlay(id);
      this.copyOverlayDialog = true;
    },

    HideEvent() {
      this.eventTitle = "";
      this.eventDialog = false;
    },

    ShowLink(id) {
      this.HideDropdown();
      this.GetAOverlay(id);
      this.GetListOverlay(id);
      this.overlayId = id;
      this.linkDialog = true;
    },

    ShowNextScreen(id) {
      this.HideDropdown();
      this.GetAOverlay(id);
      this.GetListOverlay(id);
      this.overlayId = id;
      this.screenDialog = true;
    },

    SaveLink() {
      this.id = "";
      for (var i = 0; i < this.selectOverlays.length; i++) {
        this.id += this.selectOverlays[i] + ",";
      }
      var data = {
        id: this.overlayId,
        link: this.id.slice(0, -1),
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios.post("update_link", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.GetOverlayByEvent(this.$route.params.id);
          this.linkDialog = false;
          this.Toast("success", "Saved", "");
        } else {
          this.Toast("error", "Fail!", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    SaveNextScreen() {
      var data = {
        id: this.overlayId,
        next_screen: this.selectOverlay,
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios.post("update_next_screen", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.GetOverlayByEvent(this.$route.params.id);
          this.screenDialog = false;
          this.Toast("success", "Saved", "");
        } else {
          this.Toast("error", "Fail!", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    SaveEvent() {
      var data = {
        title: this.eventTitle,
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("store_event", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.GetEvent();
          this.eventDialog = false;
          this.Toast("success", "Saved", "");
        } else {
          this.Toast("error", "Fail!", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    SaveProp() {
      if (this.component.status != 1) {
        var data = {
          id: this.component.id,
          title: this.component.title,
          component: this.component.component,
          status: this.component.status,
          event_id: this.$route.params.id,
          type: this.component.type,

        };
        const token = this.$store.state.token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        axios.post("store_overlay", data, config).then((resp) => {
          if (resp.data.status == "success") {
            this.GetOverlayByEvent(this.$route.params.id);
            this.propDialog = false;
            this.Toast("success", "Saved", "");
          } else {
            this.Toast("error", "Fail!", resp.data.message);
          }
        }).catch((err) => {
          this.CatchError(err);
        });
      } else {
        this.Toast("error", "Fail", "Cannot change when overlay is active");
      }
    },

    SaveName() {
      var data = {
        id: this.component.id,
        title: this.component.title,
        data: this.component.data,
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios.post("store_event", data, config).then((resp) => {
        if (resp.data.status == "success") {
          this.GetEvent();
          this.nameEventDialog = false;
          this.Toast("success", "Saved", "");
        } else {
          this.Toast("error", "Fail!", resp.data.message);
        }
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    SaveCopyEvent() {
      if (this.oldName == this.component.title) this.Toast("error", "Fail!", "Name Exist !");
      else {
        var data = {
          id: this.component.id,
          title: this.component.title,
        };
        const token = this.$store.state.token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        axios.post("copy_event", data, config).then((resp) => {
          if (resp.data.status == "success") {
            this.GetEvent();
            this.copyEventDialog = false;
            this.Toast("success", "Saved", "");
          } else {
            this.Toast("error", "Fail!", resp.data.message);
          }
        }).catch((err) => {
          this.CatchError(err);
        });
      }
    },

    SaveCopyOverlay() {
      if (this.oldName == this.component.title) this.Toast("error", "Fail!", "Name Exist !");
      else {
        var data = {
          id: this.component.id,
          title: this.component.title,
          event_id: this.$route.params.id,
        };
        const token = this.$store.state.token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        axios.post("copy_overlay", data, config).then((resp) => {
          if (resp.data.status == "success") {
            this.GetOverlayByEvent(this.$route.params.id);
            this.copyOverlayDialog = false;
            this.Toast("success", "Saved", "");
          } else {
            this.Toast("error", "Fail!", resp.data.message);
          }
        }).catch((err) => {
          this.CatchError(err);
        });
      }
    },

    CatchError(err) {
      try {
        if (err.response.statusText == 'Unauthorized') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('id');
          localStorage.removeItem('event_id');
          this.$router.push('login');
        } else {
          console.log(err.response.statusText);
        }
      } catch ($e) {
        console.log(err);
      }
    },

    HideDropdown() {
      const el = document.querySelector("#defaultDropdown");
      const dropdown = tailwind.Dropdown.getOrCreateInstance(el);
      dropdown.hide();
    },

    OnPage(event) {
      this.lazyParams = event;
      this.GetEvent();
    },

    GetEvent() {
      this.lazyParams.id = "";
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("show_event", this.lazyParams, config).then((resp) => {
        this.overlays = resp.data.data;
        this.total = resp.data.total;
        this.event = true;
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    GetOverlayByEvent(id) {
      this.event = false;
      var data = {
        id: id,
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("get_overlay_by_event", data, config).then((resp) => {
        this.overlays = resp.data.data;
        this.total = resp.data.total;
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    GetListOverlay(id) {
      this.lazyParams = {
        page: 0,
        first: 0,
        rows: 18,
        search: "",
        id: id,
        event_id: this.$route.params.id
      };
      const token = this.$store.state.token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("search_overlay", this.lazyParams, config).then((resp) => {
        this.listOverlays = resp.data.data;
      }).catch((err) => {
        this.CatchError(err);
      });
    },

    Merge(input) {
      let inputNGroup = input.filter((input) => input.type !== "group");
      let array = input.filter((input) => input.type === "group");
      let inputWGroup = [];
      array.forEach((element) => {
        element.element.forEach((el) => {
          inputWGroup.push(el);
        });
      });
      return inputNGroup.concat(inputWGroup);
    },

    Toast(state, sum, det) {
      this.$toast.add({
        severity: state,
        summary: sum,
        detail: det,
        group: "tr",
        life: 800,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
a.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep(.p-paginator) {
  background: #ffffff00;
}

.p-dialog .p-dialog-content {
  padding: 0 !important;
}

::v-deep(.p-paginator-rpp-options) {
  background: #ffffff00;
}
</style>