import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store';
import '../src/assets/dist/css/app.css';
import '../src/assets/dist/js/app';
import '../src/assets/dist/css/custom-font.css'; //custom font
import 'primevue/resources/themes/saga-orange/theme.css'; //theme change when install
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_baseURL;

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Toolbar from 'primevue/toolbar';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import PrimeVue from 'primevue/config';
import Paginator from 'primevue/paginator';
import Menu from 'primevue/menu';
import OverlayPanel from 'primevue/overlaypanel';
import MultiSelect from 'primevue/multiselect';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import Vue3DraggableResizable from 'vue3-draggable-resizable';
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import 'transition-style';
import VueCryptojs from 'vue-cryptojs';
const app = createApp(App);
app.directive('tooltip', Tooltip);
app.component('Toolbar', Toolbar);
app.component('Dropdown', Dropdown);
app.component('Button', Button);
app.component('Paginator', Paginator);
app.component('Menu', Menu);
app.component('OverlayPanel', OverlayPanel);
app.component('MultiSelect', MultiSelect);
app.use(VueCryptojs);
app.use(PrimeVue);
app.use(SimpleTypeahead);
app.use(Vue3DraggableResizable);
app.use(ToastService);
app.use(store).use(router).mount('#app');
