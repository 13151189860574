<template>
  <Transition name="fade" :duration="{ enter: 1000, leave: 1000 }">
    <div style="background-color: rgba(0, 0, 0, .8);position: absolute; top: 0px;font-size: 15px;"
      :style="{ 'width': state.w + 'px', 'height': state.h + 'px' }" v-show="test">
      <div style="display: flex;  justify-content: space-around; text-align: center; margin-block: 15px;">
        <div>
          <p style="color: #f2c701;font-weight: bold; margin-bottom: 10px;">PICKED</p>
          <p style="color: white;font-weight: bold;font-size: 2.22rem;">{{ pick }}</p>
        </div>
        <div>
          <p style="color: #f2c701;font-weight: bold; margin-bottom: 10px;">BANED</p>
          <p style="color: red;font-weight: bold;font-size: 2.22rem;">{{ ban }}</p>
        </div>
      </div>
      <div style="display: flex;  justify-content: center; text-align: center; margin-block: 10px;">
        <div>
          <p style="color: #f2c701;font-weight: bold;margin-bottom: 10px;">WIN RATE</p>
          <p style="color: white;font-weight: bold;font-size: 2.22rem;">{{ winRate }}</p>
        </div>
      </div>
      <div style="display: flex;  justify-content: center; text-align: center; margin-block: 10px;">
        <div>
          <h1 style="color: #f2c701;font-weight: bold; margin-block: 10px;">RECENT K/D/A</h1>
          <p style="color: white;font-weight: bold;font-size: 2rem;">{{ `${kill}/${death}/${assist}` }}</p>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>

export default {
  name: "BackStateCom",
  props: {
    state: Object,
    source: String,
  },
  data() {
    return {
      pick: this.state.heroState.pickCount || 0,
      ban: this.state.heroState.banCount || 0,
      winRate: this.state.heroState.winRate || 0,
      kill: this.state.heroState.kill || 0,
      death: this.state.heroState.death || 0,
      assist: this.state.heroState.assist || 0,
      showTemplate: true,
      show: false,
      link: process.env.VUE_APP_Link,
      test: false,
    };
  },

  mounted() {
    this.show = true;
    var self = this;
      setTimeout(() => {
        self.test = true;
      }, 2500);
      setTimeout(() => {
        self.test = false;
      }, 5000);
      // setTimeout(() => {
      //   self.test = true;
      // }, 6000);
      // setTimeout(() => {
      //   self.test = false;
      // }, 10000);

  },

  watch: {
    text: {
      deep: true,
      immediate: true,
      handler() {
      },
    },
  },
}

</script>
<style lang="scss" scoped>
.fade-enter-active {
  animation: slide-down 1s;
  z-index: 9999;
  // opacity: 1;

}

.fade-leave-active {
  animation: slide-up 1s;
  // opacity: 0;
}

.fade-enter-from {
  transform: translateY(-300px);
  // opacity: 1;

}

.fade-leave-to {
  transform: translateY(0);
  display: none;
  opacity: 0;

}

@keyframes slide-down {
  0% {
    transform: translateY(-300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    // opacity: 1;

  }

  100% {
    transform: translateY(-300px);
    // opacity: 0;

  }
}
</style>