<template>
  <p :style="{
    color: state.style.color,
    'font-family': state.style.font,
    'font-size': state.style.size + 'px',
    'text-align': state.style.align,
    'line-height': state.h +'px'
  }">
    {{ show }}
  </p>
</template>
<script>
export default {
  name: "CountDownCom",
  props: {
    second: Number,
    text: String,
    state: Object,
  },
  data() {
    return {
      count: this.second,
      message: this.text,
      lastTime: 0,
      lastSecond: this.state.time,
      show: "",
      MILLISECONDS_SECOND: 1,
      MILLISECONDS_MINUTE: 60,
      MILLISECONDS_HOUR: 60 * 60,
      MILLISECONDS_DAY: 24 * 60 * 60,
      create: 0,
      mount: 0,
    };
  },
  computed: {
    days() {
      return Math.floor(this.count / this.MILLISECONDS_DAY);
    },
    hours() {
      if (Math.floor(
        (this.count % this.MILLISECONDS_DAY) / this.MILLISECONDS_HOUR
      ) > 9) {
        return Math.floor(
          (this.count % this.MILLISECONDS_DAY) / this.MILLISECONDS_HOUR
        );
      }
      else {
        return "0" + Math.floor(
          (this.count % this.MILLISECONDS_DAY) / this.MILLISECONDS_HOUR
        );
      }
    },

    minutes() {
      if (Math.floor(
        (this.count % this.MILLISECONDS_HOUR) / this.MILLISECONDS_MINUTE
      ) > 9) {
        return Math.floor(
          (this.count % this.MILLISECONDS_HOUR) / this.MILLISECONDS_MINUTE
        );
      }
      else {
        return "0" + Math.floor(
          (this.count % this.MILLISECONDS_HOUR) / this.MILLISECONDS_MINUTE
        );
      }
    },

    seconds() {
      if (Math.floor(((this.count % 60) * 1000) / 1000) > 9) {
        return Math.floor(((this.count % 60) * 1000) / 1000);
      }
      else {
        return "0" + Math.floor(((this.count % 60) * 1000) / 1000);
      }
    },
  },
  watch: {
    $props: {
      deep: true,
      immediate: true,
      handler() {
        this.count = this.second;
        this.message = this.text;
      },
    },
  },
  methods: {
    CountDown() {
      var self = this;
      setInterval(function () {
        if (self.lastTime == 0) {
          if (self.count > 0) {
            self.count -= 1;
            self.lastTime = self.count;
            self.ChangeMessage();
          }
        }
        else {
          if (self.lastTime > 0) {
            self.lastTime -= 1;
            self.count = self.lastTime;
            self.ChangeMessage();
          }
        }
      }, 1000);

    },
    ChangeMessage() {
      var time =
        this.hours + ":" + this.minutes + ":" + this.seconds;
      var letter = ["{{hours}}", "{{minutes}}", "{{seconds}}"];
      var text = this.message;
      var string = "";
      for (var j = 0; j < letter.length; j++) {
        switch (text.includes(letter[j])) {
          case true:
            string = text.replace(letter[j], time.split(":")[j]);
            text = string;
            break;
        }
      }
      this.show = string;
    },
  },
  mounted() {
    this.CountDown();
  },
};
</script>