<template>
  <div :style="{
    'height': state.h + 'px',
    '--graph': state.style.color,
    '--percents': state.style.percent + '%',
    'float': state.style.align,
  }" class="percentage percentage-30 move"></div>
</template>
<script>
export default {
  name: "GraphCom",
  props: {
    second: Number,
    text: String,
    state: Object,
    btn: String,
  },
}
</script>
<style scoped lang="scss">
.percentage {
  background-color: var(--graph);
  width: var(--percents);
}

@for $i from 1 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: (
        $i * 1%
      );
    width: $value;
  }
}
}

.move {
  animation-name: move;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes move {
  0% {
    width: 0%;
  }
}
</style>
